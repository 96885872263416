#loginPage {
  width: 100vw;
  height: 95vh;

  display: flex;
  flex-direction: column;
  align-content: center;
}

.loginForm {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  margin: auto 0;
}

.loginForm form {
  display: flex;
  width: 30%;
  min-width: 15em;
  max-width: 20em;
  flex-direction: column;
  align-items: stretch;
}
/*
@media screen and (max-height: 15em) {
  .loginForm form {
    height: 100%;
  }
}
*/
@media screen and (max-width: 500px) {
  .loginForm form {
    width: 90%;
  }
}

.loginForm h1 {
  font-size: 3.5em;
  margin: 0;
}

.loginForm button {
  margin-top: 1em;
}
